import decode from 'jwt-decode';

export default class AuthService {
    // Initializing important variables
    // 'http://localhost:5000/api/'
    constructor(domain) {
        const urlString = process.env.REACT_APP_API_LINK
        // urlString = 'http://localhost:5000/api/'
        this.domain = domain || urlString
        // this.domain = 'http://localhost:5000/api/'
        // this.domain = 'http://localhost:15627/api/'
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.setToken = this.setToken.bind(this)
        this.getToken = this.getToken.bind(this)
        this.facebookLogin = this.facebookLogin.bind(this)
    }

    login(email, password, tenantId) {
        // Get a token from api server using the fetch api
        var vm = this;
        var newObject = {"userName": email, "password": password, "tenantId": parseInt(tenantId, 10)};

        return this.fetch(`${this.domain}Auth/Login`, {
          method: 'POST',
          headers: {
  	         'Content-Type': 'application/json',
           },
          body: JSON.stringify(newObject),
        }).then(res => {
          if(res.authToken){
            vm.setToken(res.authToken);
          }
          return Promise.resolve(res);
        }).catch(err =>{
          return Promise.resolve(err);
        })
    }

    emailLogin(email, name, collectPromo, collectPromoTwo, zipCode, bday){
        const emailLoginObject = {"Email": email, "Name": name, "OptIn": collectPromo, "OptInTwo": collectPromoTwo, "ZipCode": zipCode, "Birthday": bday}
        return this.fetch(`${this.domain}Auth/AuthUserFromEmail`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailLoginObject),
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err =>{
            return Promise.resolve(err);
        })
    }

    getAuth(number, country_code, name, tenantId, collectPromo, collectPromoTwo, zipCode, bday){
      var getAuthObject = {"CountryCode": country_code, "PhoneNumber": number, "TenantId": parseInt(tenantId, 10), "name":name, "OptIn": collectPromo, "OptInTwo": collectPromoTwo, "ZipCode": zipCode, "Birthday": bday};
      return this.fetch(`${this.domain}Auth/AuthUserFromPhoneNumber`, {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(getAuthObject),
      }).then(res => {
        return Promise.resolve(res);
      }).catch(err =>{
        return Promise.resolve(err);
      })
    }

    anonymousLogin(tenantId){
        const emailLoginObject = {"Email": "", "Name": "", "TenantId": parseInt(tenantId, 10), "OptIn": false, "OptInTwo": false, "ZipCode": "", "Birthday": ""}
        return this.fetch(`${this.domain}Auth/AuthAnonymousUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailLoginObject),
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err =>{
            return Promise.resolve(err);
        })
    }

    authPassUserId(passedUserId, name, collectPromo, zipCode, bday){
        const emailLoginObject = {"PhoneNumber": passedUserId, "CountryCode": "", "Name": name, "OptIn": collectPromo, "ZipCode": zipCode, "Birthday": bday}
        return this.fetch(`${this.domain}Auth/AuthPassUserId`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailLoginObject),
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err =>{
            return Promise.resolve(err);
        })
    }

    requestToDownload(gameId){
        const gameObject = {"gameId": gameId}
        return this.fetch(`${this.domain}services/app/Game/UserDownloadReward`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(gameObject),
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err =>{
            return Promise.resolve(err);
        })
    }

    verifyPin(number, country_code, pin, tenantId){
      var verifyPinObject = {"PhoneNumber": number, "CountryCode": country_code, "TenantId": parseInt(tenantId, 10), "Pin": pin};
      return this.fetch(`${this.domain}Auth/VerifyUserPin`, {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(verifyPinObject),
      }).then(res => {
        return Promise.resolve(res);
      }).catch(err =>{
        return Promise.resolve(err);
      })
    }

    facebookLogin(facebookResponse,tenantId){
      var facebookAccessToken = facebookResponse.accessToken;
      var vm = this;
      var facebookLoginObject = {"accessToken": facebookAccessToken, "tenantId": parseInt(tenantId, 10)};
      return this.fetch(`${this.domain}ExternalAuth/Facebook`, {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(facebookLoginObject),
      }).then(res => {
        vm.setToken(res.authToken);
        return Promise.resolve(res);
      }).catch(err =>{
        return Promise.resolve(err);
      })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from sessionStorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        let idToken;
        try {
            idToken = sessionStorage.getItem('id_token')
        } catch (e) {
            console.log("ERROR")
        }
        return idToken
    }

    logout() {
        // Clear user token and profile data from sessionStorage
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('verifiedAge');
        sessionStorage.removeItem('birthday');
        sessionStorage.removeItem('locationPermissions');
        sessionStorage.removeItem('userEmail')
        window.location.reload();
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    isAdmin() {
      const token = this.getToken();
      if(token){
        if(decode(token).rol === "api_access"){
          return true;
        }
      }
      return false;
    }

    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
            if(options.headers){
              options.headers['Authorization'] = 'Bearer ' + this.getToken()
              options.headers['Accept'] = 'application/json'
            }
        }

        return fetch(url, {
            headers,
            ...options
        }).then( response => {
            return response.json()
          }
        ).catch(
          error => console.error('Error:', error)
        )
            // .then(response => response.json())
    }

    // _checkStatus(response) {
    //     // raises an error in case response status is not a success
    //     if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
    //         return response
    //     } else {
    //       console.log(response)
    //       var error = new Error(response.statusText)
    //       error.response = response
    //       console.log(error)
    //       throw error
    //     }
    // }
}
