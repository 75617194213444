import React, {Component} from 'react';
import {getURLParameter} from "./HelpfulFunction";
import PulseLoader from "react-spinners/PulseLoader";

class ImageViewer extends Component {
    constructor(props) {
        super(props);
        const imageToShow = getURLParameter('image');
        const buttonUrl = getURLParameter('redeembuttonurl');
        this.state = {
            imageToShow: imageToShow,
            buttonUrl: buttonUrl,
            loading: true
        };
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const {loading} = this.state;
        const {tenantVariables={}, stringConstants={}} = this.props;
        document.getElementById('root').style.backgroundImage = "";
        const primaryColor = tenantVariables.primaryColor || '#ff0000';
        const secondaryColor = tenantVariables.secondaryColor || '#000';
        const redeemButtonText = tenantVariables.redeemButtonText || stringConstants.REDEEMBUTTONTEXT || "STAFF";
        if(loading){
            return <PulseLoader color={primaryColor}/>
        }
        return (
            <div style={{backgroundColor: "white", textAlign: "center"}}>
                <img src={this.state.imageToShow} style={{maxWidth: "100%", maxHeight: "100%"}} alt=""/>
                {this.state.buttonUrl &&
                    <button style={{fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none", color: secondaryColor, backgroundColor: primaryColor, background: primaryColor, margin: 15, fontFamily: "Oswald", fontWeight: '700'}} onClick={()=> window.open(this.state.buttonUrl)} className="btn btn-default btn-admin">
                        {redeemButtonText}
                    </button>
                }
            </div>


        )
    }
}

export default ImageViewer;
