import React, {Component} from 'react';
import {getURLParameter} from "./HelpfulFunction";
import {QRCodeSVG} from 'qrcode.react';
import { db, base } from '../../base';
import {confirmAlert} from "./react-confirm-alert";
import PulseLoader from "react-spinners/PulseLoader";
import '../../styles/css/RedeemedViewer.css';

let interval;

class RedeemedViewer extends Component {
    constructor(props) {
        super(props);
        const currentLocation = window.location.href;
        const currentPath = window.location.pathname;
        const language = getURLParameter('language') ||  "";
        const code = getURLParameter('code');
        const pin = getURLParameter('pin');
        const imageToShow = getURLParameter('image');
        const showQrCode = getURLParameter('qrcode');
        const id = getURLParameter('id');
        const prize = getURLParameter('prize');
        const uid = getURLParameter('uid');
        const gameId = getURLParameter('gameid');
        let cleanHost = currentLocation.replace(currentPath, "/");
        cleanHost = cleanHost.split("?")[0] + "?language=" + language;
        this.state = {
            imageToShow: imageToShow,
            code: code,
            showQrCode: showQrCode,
            pin: pin,
            id: id,
            isWinner: false,
            loading: true,
            prize: prize,
            requested: false,
            language: language,
            uid: uid,
            redirectUrl: cleanHost,
            gameId: gameId
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "white";
        this.setState({
            loading: false
        }, () => {
            const stringConstants = this.props.stringConstants || {};
            const tenantVariables = this.props.tenantVariables || {};
            confirmAlert({
                title: stringConstants.WAITTEXT,
                message: stringConstants.CONFIRMINSTOREBODY,
                cancelLabel: stringConstants.CLOSETEXT,
                confirmLabel: stringConstants.REDEEMBUTTONTEXT,
                onConfirm: () => {
                    console.log("CONFIRM DETECTED")
                    this.checkIfPrizeUsed();
                },
                onCancel: () => {
                    window.open(this.state.redirectUrl)
                },
                variables: tenantVariables
            })
        })
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "black";
        if(interval){
            clearInterval(interval);
        }
    }

    async checkIfPrizeUsed(){
        const uid = this.state.uid;
        const prize = this.state.prize;
        const gameId = this.state.gameId;
        if(!prize || typeof prize !== "string"){
            return;
        }
        let winner = true;
        try {
            await db.collection('prizeWon').doc(prize).update({uid: uid, prizeWonId: prize, timeRedeemed: new Date().getTime(), redeemed:true});
        } catch (e) {
            console.log(e)
            winner = false;
        }
        this.setState({
            loading: false,
            isWinner: winner,
            requested: true
        })
    }

    render() {
        let {isWinner, loading, requested=false} = this.state;
        const tenantVariables = this.props.tenantVariables || {};
        const primaryColor = tenantVariables.primaryColor || "#000";
        let backGroundColor = "red";
        let successText = "SORRY!";
        if(loading){
            return <PulseLoader color={primaryColor}/>
        }
        let textColor = "white";
        if(isWinner){
            backGroundColor = "lightgreen";
            successText = "GOOD TO GO!"
            textColor = "black";
        }
        if(!requested){
            return <div></div>
        }
        return (
            <div style={{textAlign:'center', minHeight:"100vh", backgroundColor: backGroundColor, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                <h1 style={{color: textColor, fontFamily: "Oswald"}}>{successText}</h1>
                {isWinner ?
                    <div style={{width: "10rem", height: "10rem", backgroundColor: "rgba(0, 0, 0, 0.1)", borderRadius: "50%", display: "inline-flex", padding: "1rem", justifyContent: "center", alignItems: "center", fontSize: "5rem"}}>
                        👍
                    </div>
                    :
                    <>
                        <div className="xStyles"/>
                        <div style={{display: "inline-flex", justifyContent: "center", alignItems: "center", color: "white"}}>
                            <span style={{fontSize: "1rem"}}>This offer has already been used</span>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default RedeemedViewer;
